<script setup lang="ts">
import { ToastType } from '~/lib/models/ui/toast'

const props = defineProps<{
  identifier: string
  type: string
  location: string
  duration: number
}>()

const emit = defineEmits(['removed'])
const shown = ref(true)

/* Computed */
const computedClass = computed(() => {
  let sClass = ''

  switch (props.type) {
    case ToastType.SUCCESS:
      sClass += 'border-mcd-lightGreen'
      break
    case ToastType.INFO:
      sClass += 'border-mcd-lightBlue'
      break
    case ToastType.WARNING:
      sClass += 'border-mcd-main'
      break
    case ToastType.ERROR:
      sClass += 'border-mcd-lightRed'
      break
  }

  return sClass
})

const iconSrc = computed(() => `/images/icons/toast/${props.type}.svg`)

/* Methods */
const hideToast = () => {
  shown.value = false
  emit('removed', props.identifier)
}

/* Lifecycle */
onMounted(async () => {
  for (let i = 1; i <= props.duration; i++) {
    await new Promise(resolve => setTimeout(resolve, 1000))
  }
  hideToast()
})
</script>

<template>
  <div
    data-test="mcd-toast"
    class="relative z-[100] mx-2 mb-1 flex min-h-[4rem] cursor-pointer flex-col justify-center rounded-md border-l-4 bg-mcd-black p-4 shadow-lg"
    :class="computedClass"
    @click="hideToast"
  >
    <div class="flex items-center">
      <img :src="iconSrc" class="mr-2 mt-1 h-auto w-4 self-start" alt="toast-icon" />
      <slot />
    </div>
  </div>
</template>
